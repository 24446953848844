import React from 'react'
import './nav.css'
import {HiHome} from 'react-icons/hi'
import {BsFillPersonFill} from 'react-icons/bs'
import {FaBook} from 'react-icons/fa'
import {MdMessage} from 'react-icons/md'
import { useState } from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')

  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}> <HiHome/> </a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}> <BsFillPersonFill/> </a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}> <FaBook/> </a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}> <MdMessage/> </a>
    </nav>
  )
}

export default Nav