import React from 'react'
import './portfolio.css'
import COUPLE from '../../assets/couplistic.png'
import ENG from '../../assets/rails_engine.png'
import VP from '../../assets/viewing_party.png'
import BS from '../../assets/bank_site.png'
import EC from '../../assets/ecommerce.png'

const data = [
  {
    id: 1,
    image: COUPLE,
    title: 'Couplistic: Romantic Event Planner',
    github: 'https://github.com/Love-Rangers',
    live: ''
  },
  {
    id: 2,
    image: ENG,
    title: 'Rails Engine: API data Expression',
    github: 'https://github.com/austinandrade/rails-engine',
    live: ''
  },
  {
    id: 3,
    image: VP,
    title: 'Viewing Party: Private Movie Planner and Scheduler',
    github: 'https://github.com/austinandrade/viewing_party',
    live: ''
  },
  {
    id: 4,
    image: BS,
    title: 'Sample banking site',
    github: 'https://github.com/austinandrade/sample_banking_app',
    live: 'https://austinandrade-samplebanksite.netlify.app/'
  },
  {
    id: 5,
    image: EC,
    title: 'Sample ecommerce site',
    github: 'https://github.com/austinandrade/sample_ecommerce_app',
    live: 'https://austinandrade-sample-ecommerce-app.vercel.app/'
  }
]
const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({id, image, title, github, live}) =>
          {
            return (
              <article key={id} className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                    <a href={github} className='btn portfolio__item-button' target='_blank'> Github</a>
                    {live !== '' ? (<a href={live} className='btn btn-primary portfolio__item-button ' target='_blank'> Live Demo</a> ) : '' }
                </div>
              </article>
            )
          })
        }

      </div>
    </section>
  )
}

export default Portfolio