import React from 'react'
import './about.css'
import MEPNG from '../../assets/youngme.png'
import MEWEBP from '../../assets/youngme.webp'
import {FaAward} from 'react-icons/fa'
import {BsFolderFill} from 'react-icons/bs'
import ImageFallback from '../ImageFallback'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <ImageFallback src={MEWEBP} alt="About Me Image" fallback={MEPNG} />
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <div className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>2+ years working</small>
            </div>

            <div className='about__card'>
              <BsFolderFill className='about__icon'/>
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </div>
          </div>

          <p>
           I work professionally designing web applications using ReactJS, Ruby on Rails, and Elm. <br/>
            <br/> When I'm not coding, I love to game and create music 🎹
          </p>

          <a href="#contact" className='btn btn-primary'> Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About