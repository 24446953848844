import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {RiGithubFill} from 'react-icons/ri'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href="https://www.linkedin.com/in/austinandrade/" target="_blank"> <BsLinkedin/> </a>
      <a href="https://github.com/austinandrade" target="_blank"> <RiGithubFill/> </a>

    </div>
  )
}

export default HeaderSocials