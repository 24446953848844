import React, { useState } from 'react'
import './header.css'
import CTA from './CTA'
import MEPNG from '../../assets/full_headshot_cropped.png'
import MEWEBP from '../../assets/full_headshot_cropped.webp'
import HeaderSocial from './HeaderSocials'
import ImageFallback from '../ImageFallback'

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5> Hello I'm </h5>
        <h1> Austin Andrade </h1>
        <h5 className='text-light'> Fullstack Developer </h5>
        <CTA/>
        <HeaderSocial/>

        <div className='me'>
          <ImageFallback src={MEWEBP} fallback={MEPNG} alt='me'/>
        </div>

        <a href="#contact" className='scroll__down'> Scroll Down </a>
      </div>
    </header>
  )
}

export default Header