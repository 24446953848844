import React from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {TfiLinkedin} from 'react-icons/tfi'
import { useRef } from 'react';
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kbf0eea', 'template_mfcjg1a', form.current, 'NexFCs090eEZJV_fM')

    e.target.reset()

    alert("Message successfully sent.")
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <a href="mailto:contact@austinandrade.com" target='_blank'>Send a message</a>
          </article>
          <article className="contact__option">
            <TfiLinkedin className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            <a href="https://www.linkedin.com/in/austinandrade/" target='_blank'>Connect with me</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full name" required />
          <input type="text" name="email" placeholder="Your Email" required />
          <textarea name="message" rows="7" placeholder='Your Message' required/>
          <button type='submit' className='btn btn-primary'> Send Message</button>
          
        </form>
      </div>
    </section>
  )
}

export default Contact